import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const userDataSlice = createSlice({
    name: 'userDataSlice',
    initialState: { 
        id: '',
        tradingAccountId: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        ownerId: '',
        groupId: null,
        deskId: '',
        itemId: '',
        isReal: true,
        dateOfBirth: null,
        hasDeposit: false,
        isEnabled: true,
        country: '',
        city: null,
        zipCode: null,
        state: null,
        address: null,
        referrer: null,
        affiliateId: null,
        affiliateUserId: null,
        acceptTerms: true,
        suppliedDocs: false,
        tradeAccounts: [],
        additionalFields: {}
    },
    reducers: {
        change(state, action) {
            return { ...state, ...action.payload };
        }
    }
});

export default userDataSlice.reducer;
export const { change } = userDataSlice.actions;

export const update = (token, data) => async (dispatch) => {
    if (data) {
        dispatch(change(data));
    } else {
        try {
            const response = await fetch(`/api/profile/accountInfo`, {            
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token || Cookies.get('token')}`
                },
            });
            const data = await response.json();
            if (data.isSuccess) {
                dispatch(change(data.data));
            }
        } catch (error) {
            console.error('Error fetching account info:', error);
            toast.error('Error fetching account info. Please try again.');
        }
    }
};