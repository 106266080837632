import { createSlice } from "@reduxjs/toolkit";

const settingDataSlice = createSlice({
    name: 'settingDataSlice',
    initialState: {},
    reducers: {
        change(state, action) {
            return { ...state, ...action.payload };
        }
    }
});

export default settingDataSlice.reducer;
export const { change } = settingDataSlice.actions;