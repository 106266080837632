import { useMemo } from 'react';
import { useTranslations } from '../../client/hooks/useTranslations';
import style from './PageNotFoundStyle.module.scss';
import { Helmet } from 'react-helmet';

export default function PageNotFound() {

    const tr = useTranslations(useMemo(() => [
        'textPageNotFound',
        'textSorryThePage', 
        'textReturnToOur', 
        'textLinkHomepage', 
        'textContinueBrowsing', 
        'notFoundPageTitleName'
    ], []));

    return(
        <div className={style.notfound_page}>
            <Helmet>
                <title>{tr?.notFoundPageTitleName}</title>
            </Helmet>
            <div className={style.wrapper}>
                <h3 data-tr="textPageNotFound">{tr?.textPageNotFound}</h3>
                <p data-tr="textSorryThePage">{tr?.textSorryThePage}</p>
                <p data-tr="textReturnToOur textContinueBrowsing">{tr?.textReturnToOur} <a href="/" data-tr="textLinkHomepage">{tr?.textLinkHomepage}</a> {tr?.textContinueBrowsing}</p>
                {/* <video 
                    src={require('./img/logo-bull-bear.mp4')} 
                    autoPlay 
                    loop 
                    muted 
                    playsInline 
                    style={{ width: '100%', height: 'auto' }}
                /> */}
            </div>
        </div>
    )
}