import { useState, useEffect } from 'react';
import Cookies from "js-cookie";

async function fetchTranslation(keys) {
  const response = await fetch('/ui-api/key-translation?languageCode=' + Cookies.get('langCode'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      keys: keys
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const responseData = await response.json();

  return responseData.data;
}

function useTranslations(keys = []) {
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const result = await fetchTranslation(keys);
        setTranslations(result);
      } catch (error) {
        console.error('Ошибка при загрузке переводов:', error);
      }
    };

    loadTranslations();
  }, [keys]);

  return translations;
}

export { useTranslations, fetchTranslation };
