import { lazy, useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";
import { rootReducer as ClientReducer } from './client/redux/reducers/rootReducer';
import { rootReducer as AdminReducer } from './admin/redux/reducers/rootReducer';
import Cookies from 'js-cookie';
import PageNotFound from './component/PageNotFound/PageNotFound';

const Admin = lazy(() => import('./admin/Admin'));
const Client = lazy(() => import('./client/Client'));


function App() {
  
  const mainStore = configureStore({ reducer: ClientReducer });
  const adminStore = configureStore({ reducer: AdminReducer });

  const [isAdmin, setIsAdmin] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const token = Cookies.get('token');

  useEffect(() => {
    if (token) {
      getIsAdmin();
      getIsBlocked();
    }
  }, [token]);

  const getIsAdmin = async () => {
    try {
        const response = await fetch('/isAdmin', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        const data = await response.json();

        if (data.isSuccess) {
            setIsAdmin(data.data.isAdmin);
        }
    } catch (error) {
        console.error('Error checking admin status:', error);
    }
};

const getIsBlocked = async () => {
  try {
      const response = await fetch('/ui-api/is-country-blocked', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
      });

      const data = await response.json();

      if (data.isSuccess) {
          setIsBlocked(data.data);
      }
  } catch (error) {
      console.error('Error checking blocked status:', error);
  }
};

  return (
    <>

      {
        window.location.pathname.includes('admin') ?
          isAdmin ?
            <Provider store={adminStore}>
              <Admin />
            </Provider> : 
            <PageNotFound /> :
        <Provider store={mainStore}>
          { isBlocked ? 
            <h2 style={{fontSize: '32px', textAlign: 'center', marginTop: '20%'}}>Not available in your country</h2> : 
            <Client /> 
          }
        </Provider> 
      }
    </>
  );
}

export default App;
